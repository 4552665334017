// eslint-disable-next-line import/prefer-default-export
export const uniqWith = (arr, fn) => arr.filter((element, index) => arr.findIndex((step) => fn(element, step)) === index)

export const pick = (object, keys) => keys.reduce((obj, key) => {
  // eslint-disable-next-line no-prototype-builtins
  if (object && object.hasOwnProperty(key)) {
    // eslint-disable-next-line no-param-reassign
    obj[key] = object[key]
  }
  return obj
}, {})
