import { dispatch } from 'store/store'
import {
  ADD_CATEGORY_PRODUCTS, ADD_DEDICATED_PRODUCTS, ADD_SEARCH_PRODUCTS, RESET_KEEP_NAVIGATION_STATES, KEEP_STATES_ON_NAVIGATE,
  PAGE_NAVIGATION,
} from 'store/types'

export const keepStateOnNavigate = (stateKey) => () => {
  dispatch({ type: KEEP_STATES_ON_NAVIGATE, payload: { stateKeys: [stateKey] } })
}

export const keepStatesOnNavigate = (stateKeys) => () => {
  dispatch({ type: KEEP_STATES_ON_NAVIGATE, payload: { stateKeys } })
}

export const resetKeepNavigationStates = () => {
  dispatch({ type: RESET_KEEP_NAVIGATION_STATES })
}

export const pageNavigation = (payload) => {
  dispatch({ type: PAGE_NAVIGATION, payload })
}

export const addCategoryProducts = (payload) => {
  dispatch({ type: ADD_CATEGORY_PRODUCTS, payload })
}

export const addSearchProducts = (payload) => {
  dispatch({ type: ADD_SEARCH_PRODUCTS, payload })
}

export const addDedicatedProducts = (payload) => {
  dispatch({ type: ADD_DEDICATED_PRODUCTS, payload })
}
