export const SET_SEARCH_RESULT_DATA = 'SET_SEARCH_RESULT_DATA'
export const SET_SEARCH_RESULT_PAGE_COUNTER = 'SET_SEARCH_RESULT_PAGE_COUNTER'
export const NOTIFICATION_VERSION = 'NOTIFICATION_VERSION'
export const SET_INNER_WIDTH = 'SET_INNER_WIDTH'
export const SET_SEARCH_PAGE_SORT_ORDER = 'SET_SEARCH_PAGE_SORT_ORDER'
export const ON_FAVORITE_BUTTON_CLICK = 'ON_FAVORITE_BUTTON_CLICK'
export const HYDRATE_AMOUNT_INDICATOR = 'HYDRATE_AMOUNT_INDICATOR'
export const HYDRATE_PRODUCTS = 'HYDRATE_PRODUCTS'
export const EMPTY_REDUX_IN_CATEGORY_PAGE = 'EMPTY_REDUX_IN_CATEGORY_PAGE'
export const TRIGGER_MODAL_IS_OPEN = 'TRIGGER_MODAL_IS_OPEN'
export const SET_SEARCH_MODAL_DATA = 'SET_SEARCH_MODAL_DATA'
export const SET_SEARCH_MODAL_INPUT_VALUE = 'SET_SEARCH_MODAL_INPUT_VALUE'
export const SET_SEARCH_MODAL_NO_HITS = 'SET_SEARCH_MODAL_NO_HITS'
export const TRIGGER_NAVIGATION = 'TRIGGER_NAVIGATION'
export const TRIGGER_RIGHT_DRAWER_ITEM_PRESSED = 'TRIGGER_RIGHT_DRAWER_ITEM_PRESSED'
export const CLOSE_LEFT_DRAWER_ACTION = 'CLOSE_LEFT_DRAWER_ACTION'
export const SET_BACKGROUND_SHADOW = 'SET_BACKGROUND_SHADOW'
export const SET_CATEGORIES_FOR_CSR = 'SET_CATEGORIES_FOR_CSR'
export const REVERSE_ON_SALE = 'REVERSE_ON_SALE'
export const REVERSE_SWITCH_KEY = 'REVERSE_SWITCH_KEY'

// pageReducer
export const PAGE_NAVIGATION = 'PAGE_NAVIGATION'
export const KEEP_STATES_ON_NAVIGATE = 'KEEP_STATES_ON_NAVIGATE'
export const RESET_KEEP_NAVIGATION_STATES = 'RESET_KEEP_NAVIGATION_STATES'
export const ADD_CATEGORY_PRODUCTS = 'ADD_CATEGORY_PRODUCTS'
export const ADD_SEARCH_PRODUCTS = 'ADD_SEARCH_PRODUCTS'
export const ADD_DEDICATED_PRODUCTS = 'ADD_DEDICATED_PRODUCTS'
