import cloneDeep from 'lodash.clonedeep'
import { isCategoryPage } from 'utils/app'
import { pick, uniqWith } from 'utils/lodah'
import {
  ADD_CATEGORY_PRODUCTS, ADD_DEDICATED_PRODUCTS, ADD_SEARCH_PRODUCTS, RESET_KEEP_NAVIGATION_STATES, KEEP_STATES_ON_NAVIGATE,
  PAGE_NAVIGATION,
} from '../types'

/** This reducers state will reset on every page navigation.
 * If you wish to keep a state on a navigation, provide an
 * array of state keys to keep to statesToKeep state varaiable.
 * The state for the provided state keys will be kept on the
 * next link click.
 */

const getInitialState = (statesToKeep = []) => ({
  statesToKeep,
  category: {
    lastCategoryUrl: null,
    products: [],
  },
  search: {
    products: [],
  },
  dedicated: {
    products: [],
  },
})

// eslint-disable-next-line no-underscore-dangle
const isProductEqual = (a, b) => JSON.stringify(a._id) === JSON.stringify(b._id)

const addProductsToPage = (state, page, products) => {
  const allProducts = uniqWith([...state[page].products, ...products], isProductEqual)
  const newState = cloneDeep(state)
  newState[page].products = allProducts
  return newState
}

const setLastUrl = (state, url) => {
  const newState = cloneDeep(state)
  if (isCategoryPage(url)) {
    newState.category.lastCategoryUrl = url
  }
  return newState
}

const pageReducer = (state = getInitialState(), action = { type: '', payload: {} }) => {
  switch (action.type) {
  case PAGE_NAVIGATION: {
    const { preventKeepState, url } = action.payload
    const { statesToKeep } = state
    const keptStates = pick(state, statesToKeep)
    let newState = preventKeepState ? getInitialState() : { ...getInitialState(), ...keptStates }
    newState = setLastUrl(newState, url)
    return newState
  }
  case KEEP_STATES_ON_NAVIGATE: {
    const { stateKeys } = action.payload
    return { ...state, statesToKeep: stateKeys }
  }
  case RESET_KEEP_NAVIGATION_STATES: {
    return { ...state, statesToKeep: [] }
  }
  case ADD_CATEGORY_PRODUCTS: {
    const { products } = action.payload
    return addProductsToPage(state, 'category', products)
  }
  case ADD_SEARCH_PRODUCTS: {
    const { products } = action.payload
    return addProductsToPage(state, 'search', products)
  }
  case ADD_DEDICATED_PRODUCTS: {
    const { products } = action.payload
    return addProductsToPage(state, 'dedicated', products)
  }
  default:
    return state
  }
}

export default pageReducer
