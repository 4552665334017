/* eslint-disable no-underscore-dangle */

import cloneDeep from 'lodash.clonedeep'
import { DEFAULT, switchKeys } from '../../helper'
import {
  SET_SEARCH_RESULT_DATA,
  SET_SEARCH_RESULT_PAGE_COUNTER,
  SET_INNER_WIDTH,
  SET_SEARCH_PAGE_SORT_ORDER,
  ON_FAVORITE_BUTTON_CLICK,
  HYDRATE_PRODUCTS,
  HYDRATE_AMOUNT_INDICATOR,
  TRIGGER_MODAL_IS_OPEN,
  SET_SEARCH_MODAL_DATA,
  SET_SEARCH_MODAL_INPUT_VALUE,
  SET_SEARCH_MODAL_NO_HITS,
  TRIGGER_RIGHT_DRAWER_ITEM_PRESSED,
  SET_BACKGROUND_SHADOW,
  REVERSE_ON_SALE,
  REVERSE_SWITCH_KEY,
} from '../types'

const emptySearchResultData = {
  aggregations: {
    mappedCategoryNames: {
      buckets: [],
    },
    programNames: {
      buckets: [],
    },
    brands: {
      buckets: [],
    },
  },
  hits: undefined,
}

// TODO Remove searchPageSortOrder when dedicated page isn't using it anymore.
const getInitialState = () => ({
  searchResultPageCounter: 0,
  favoriteButtonIsFilled: false,
  searchPageSortOrder: DEFAULT,
  searchResultData: emptySearchResultData,
  productsHydrate: false,
  amountIndicateHydrate: false,
  innerWidth: 0,
  searchModalIsOpen: false,
  searchModalData: { hits: undefined, aggregations: [], totalValue: 0 },
  searchModalInputValue: '',
  searchModalNoHits: false,
  rightDrawerItem: false,
  backgroundShadow: false,
  isOnSale: false,
  switchKeys: Object.values(switchKeys).reduce((acc, { key }) => ({ ...acc, [key]: false }), {}),
})

// eslint-disable-next-line default-param-last
const mainReducer = (state = getInitialState(), action) => {
  switch (action.type) {
  case SET_SEARCH_RESULT_PAGE_COUNTER:
    return { ...state, searchResultPageCounter: action.payload }
  case HYDRATE_PRODUCTS:
    return { ...state, productsHydrate: action.payload }
  case TRIGGER_RIGHT_DRAWER_ITEM_PRESSED:
    return { ...state, rightDrawerItem: action.payload }
  case HYDRATE_AMOUNT_INDICATOR:
    return { ...state, amountIndicateHydrate: action.payload }
  case ON_FAVORITE_BUTTON_CLICK:
    return { ...state, favoriteButtonIsFilled: action.payload }
  case TRIGGER_MODAL_IS_OPEN:
    return { ...state, searchModalIsOpen: action.payload }
  case SET_SEARCH_MODAL_DATA:
    return { ...state, searchModalData: cloneDeep(action.payload) }
  case SET_SEARCH_MODAL_NO_HITS:
    return { ...state, searchModalNoHits: action.payload }
  case SET_SEARCH_MODAL_INPUT_VALUE:
    return { ...state, searchModalInputValue: action.payload }
  case SET_SEARCH_RESULT_DATA:
    return { ...state, searchResultData: cloneDeep(action.payload) }
  case SET_INNER_WIDTH:
    return { ...state, innerWidth: action.payload }
  case SET_SEARCH_PAGE_SORT_ORDER:
    return { ...state, searchPageSortOrder: action.payload }
  case SET_BACKGROUND_SHADOW:
    return { ...state, backgroundShadow: action.payload }
  case REVERSE_ON_SALE:
    return { ...state, isOnSale: action.payload }
  case REVERSE_SWITCH_KEY:
    return { ...state, switchKeys: { ...state.switchKeys, [action.payload.key]: action.payload.active } }

  default:
    return state
  }
}
export default mainReducer
