/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import { MAPBOX_ACCESS_TOKEN } from 'configs/app'
import {
  EXPERIMENTAL_ABOUT_PROGRAM, EXPERIMENTAL_ARTICLE_CATEGORIES,
  EXPERIMENTAL_GEO_INFO,
  EXPERIMENTAL_PRICE_HISTORY,
} from 'configs/experimental'
import dayjs from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import { getRangeFromFilterState, getSelectedIdsFromFilterState } from 'pages/se/category/utils'
import { JIROY_API_URL } from '../config'
import { sitemaps } from '../helper'

const SEEN_PRODUCTS = 'SEEN_PRODUCTS'

export const safeFetchJSON = async (url, options) => {
  try {
    const opts = cloneDeep(options) || {}
    if (!opts.headers) {
      opts.headers = {
        'Accept-Encoding': 'gzip, br',
        'Content-Type': 'application/json',
      }
    }

    const response = await fetch(url, opts)
    const { data } = await response.json()

    return { data, status: response.status, error: response.error }
  } catch (err) {
    console.log(err)
  }

  return { data: null, status: 400 }
}

export const geocodeAddress = async (address) => {
  try {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(address)}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
    const response = await fetch(url, {
      'Accept-Encoding': 'gzip, br',
      'Content-Type': 'application/json',
    })
    const data = await response.json()

    return data || null
  } catch (err) {
    console.log(err)
  }

  return { data: null, status: 400 }
}

const getEmptyMetaData = () => ({
  h1: null,
  title: null,
  description: null,
  links: [],
  body: null,
  ingress: null,
})

export const getFilterQuery = (keyValues) => {
  if (keyValues.length === 0) {
    return ''
  }
  if (keyValues.filter(({ active }) => active).length === 0) {
    return ''
  }
  return `?${keyValues.filter(({ active }) => active).map(({ key, active }) => `${key}=${active}`).join('&')}`
}

const getMetaDataFromResult = (metaDataFromBackend) => {
  const {
    seoTitle, h1, ingress, metaDescription, body,
  } = metaDataFromBackend || {}

  return {
    ...getEmptyMetaData(),
    h1: h1 || null,
    ingress: ingress || null,
    description: metaDescription || null,
    body: body || null,
    title: seoTitle || null,
  }
}

const filterOutInvalidGeoInfo = (geoInfo) => {
  if (!Array.isArray(geoInfo?.stores)) {
    return null
  }

  const validStores = geoInfo.stores.filter((store) => !!store.id && !!(store.openingHours || []).every((hours) => !!hours.day))

  const validGeoInfo = cloneDeep(geoInfo)
  validGeoInfo.stores = validStores
  return validGeoInfo
}

function startOfDay(date) {
  const newDate = new Date(date)
  newDate.setHours(0, 0, 0, 0)
  return newDate
}

const getPriceHistoryWithFilledInGaps = (_data) => {
  const data = _data.map((x) => ({ ...x, date: startOfDay(x.date) }))
  if (!data || data.length === 0) {
    return []
  }
  const today = new Date()
  const lastObjectInData = data[data.length - 1]
  const lastDate = new Date(lastObjectInData.date)
  const TWENTY_FOUR_HOURS = 1000 * 60 * 60 * 24

  const result = data.reduce((acc, cur) => {
    if (acc.length === 0) {
      return [{ ...cur, date: startOfDay(cur.date) }]
    }
    const lastObjectInAcc = acc[acc.length - 1]
    const milliseconds = Date.UTC(startOfDay(cur.date).getFullYear(), startOfDay(cur.date).getMonth(), startOfDay(cur.date).getDate()) - Date.UTC(startOfDay(lastObjectInAcc.date).getFullYear(), startOfDay(lastObjectInAcc.date).getMonth(), startOfDay(lastObjectInAcc.date).getDate())
    const days = milliseconds / TWENTY_FOUR_HOURS
    if (days > 1) { // only fill in gaps if there are missing days
      const gapData = []
      const gapDate = new Date(lastObjectInAcc.date)
      for (let i = 1; i < days; i++) {
        gapDate.setDate(gapDate.getDate() + 1)
        gapData.push({
          date: startOfDay(gapDate.toISOString()),
          originalPrice: lastObjectInAcc.originalPrice,
          price: lastObjectInAcc.price,
        })
      }
      acc.push(...gapData)
    }
    return [...acc, { ...cur, date: startOfDay(cur.date) }]
  }, [])

  if (lastDate < today) {
    const milliseconds = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) - Date.UTC(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate())
    const days = (milliseconds / TWENTY_FOUR_HOURS) + 1
    if (days > 1) { // only fill in gaps if there are missing days
      const gapData = []
      const gapDate = new Date(lastDate)
      for (let i = 1; i < days; i++) {
        gapDate.setDate(gapDate.getDate() + 1)
        gapData.push({
          date: startOfDay(gapDate.toISOString()),
          originalPrice: lastObjectInData.originalPrice,
          price: lastObjectInData.price,
        })
      }
      result.push(...gapData)
    }
  }

  return result.slice(-30)
}

export const ElasticSearch = {
  getSuggestions: async (query) => safeFetchJSON(`${JIROY_API_URL}/v6/elasticsearch/suggestions?query=${query}`),
  getTreeFilterChildrenInternalLinks: async (filterLevels, filters, sortOrder = 'default', isOnSale = false, isInstock = false) => {
    const filterQuery = getFilterQuery([
      { key: 'sales', active: isOnSale },
      { key: 'instock', active: isInstock },
    ])
    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v6/elasticsearch/getClosestInternalLinks/se${filterQuery}`, {
      method: 'POST',
      body: JSON.stringify({ category: filterLevels, sortOrder, filters }),
    })

    return data
  },
  getProgramInfo: async ({ programName }) => {
    if (!EXPERIMENTAL_ABOUT_PROGRAM) {
      return null
    }

    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v6/elasticsearch/getProgramInfo?programName=${encodeURIComponent(programName)}`)

    return data
  },
  getProductsFromCategory: async ({
    category = null, page, sortBy, size, priceRange, saleRange, colorsFilter, sizesFilter, genderFilter,
    searchQuery, brandsFilter, isSearch = false, isOnSale = false, isInstock = false,
  }) => {
    const filterQuery = getFilterQuery([
      { key: 'sales', active: isOnSale },
      { key: 'instock', active: isInstock },
    ])
    const pricePercentiles = [0, 25, 65, 85, 99, 100]
    // TODO Remove this when this is pushed in backend.
    if (priceRange === null) {
      // eslint-disable-next-line no-param-reassign
      priceRange = undefined
    }

    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v6/elasticsearch/productFromCategory${filterQuery}`, {
      method: 'POST',
      body: JSON.stringify({
        category,
        page,
        sortBy,
        size,
        priceRange,
        pricePercentiles,
        colorsFilter,
        sizesFilter,
        genderFilter,
        brandsFilter,
        searchQuery,
        isSearch,
        saleRange,
      }),
    })

    return data
  },
  getProductsFromSpecificProgram: async ({
    category = null, page, sortBy, size, priceRange, saleRange, colorsFilter, sizesFilter, genderFilter,
    searchQuery, brandsFilter, isSearch = false, program, isOnSale = false, isInstock = false,
  }) => {
    const filterQuery = getFilterQuery([
      { key: 'sales', active: isOnSale },
      { key: 'instock', active: isInstock },
    ])
    const pricePercentiles = [0, 25, 65, 85, 99, 100]
    // TODO Remove this when this is pushed in backend.
    if (priceRange === null) {
      // eslint-disable-next-line no-param-reassign
      priceRange = undefined
    }

    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v6/elasticsearch/productsFromSpecificProgram${filterQuery}`, {
      method: 'POST',
      body: JSON.stringify({
        category,
        page,
        sortBy,
        size,
        priceRange,
        pricePercentiles,
        colorsFilter,
        sizesFilter,
        genderFilter,
        brandsFilter,
        searchQuery,
        isSearch,
        saleRange,
        program,
      }),
    })

    return data
  },
  /**
   * @deprecated This is broken. Don't touch
   */
  getFiltersForCategory: async ({
    category, searchQuery, filters = {}, isSearch = false, isOnSale = false, isInstock = false,
  }) => {
    const pricePercentiles = [0, 25, 65, 85, 99, 100]
    const filterQuery = getFilterQuery([
      { key: 'sales', active: isOnSale },
      { key: 'instock', active: isInstock },
    ])
    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v6/elasticsearch/filtersForCategory${filterQuery}`, {
      method: 'POST',
      body: JSON.stringify({
        category,
        pricePercentiles,
        searchQuery: searchQuery || '',
        isSearch,
        filters,
      }),
    })

    return data
  },
  getProduct: async (sku, categoryFriendlyUrl) => {
    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v6/elasticsearch/getProduct?categoryFriendlyUrl=${encodeURIComponent(categoryFriendlyUrl)}&sku=${encodeURIComponent(sku)}&id=1`)

    return data
  },
  getGeoInfoForProgram: async (programName) => {
    if (!EXPERIMENTAL_GEO_INFO) {
      return null
    }

    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v6/elasticsearch/getGeoInfoForProgram?programName=${encodeURIComponent(programName)}`)
    return filterOutInvalidGeoInfo(data) || null
  },
  getGroupedPrograms: async ({ categories = [] }) => {
    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v6/elasticsearch/om-oss/groupedPrograms`, {
      method: 'POST',
      body: JSON.stringify({ categories }),
    })

    return data
  },
  /**
   * @deprecated
   */
  getAggregatedCategories: async () => {
    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v1/elasticsearch/om-oss/aggregatedCategories`)
    return data
  },
  /**
   * @deprecated
   */
  getTopRankedPrograms: async ({ size = 50, categories = [] }) => {
    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v3/elasticsearch/om-oss/getTopRankedPrograms/${size}`, {
      method: 'post',
      body: JSON.stringify({ categories }),
    })

    return data
  },
  getDedicatedPages: async () => {
    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v1/elasticsearch/dedicated-pages/getAll`)
    return data
  },
  getDedicatedPage: async (kebabName, config) => {
    const filterQuery = getFilterQuery([
      { key: 'sales', active: config.isOnSale },
      { key: 'instock', active: config.isInstock },
    ])
    return safeFetchJSON(`${JIROY_API_URL}/v5/elasticsearch/dedicated-page/${kebabName}${filterQuery}`, {
      method: 'POST',
      body: JSON.stringify(config),
    })
  },
  getNavigationTree: async (treeData, keyArray, filters = {}, searchQuery = '', isOnSale = false, isInstock = false) => {
    const priceRange = getRangeFromFilterState(filters.priceFilter?.state)
    const saleRange = getRangeFromFilterState(filters.saleFilter?.state)
    const colorsFilter = getSelectedIdsFromFilterState(filters.colorFilter?.state)
    const sizesFilter = getSelectedIdsFromFilterState(filters.sizeFilter?.state)
    const genderFilter = getSelectedIdsFromFilterState(filters.genderFilter?.state)
    const brandsFilter = getSelectedIdsFromFilterState(filters.brandFilter?.state)
    const filterValues = {
      priceRange, colorsFilter, sizesFilter, genderFilter, saleRange, brandsFilter,
    }
    const filterQuery = getFilterQuery([
      { key: 'sales', active: isOnSale },
      { key: 'instock', active: isInstock },
    ])
    return safeFetchJSON(`${JIROY_API_URL}/v6/elasticsearch/nagivation/nagivation-tree/${keyArray.join()}${filterQuery}`, {
      method: 'POST',
      body: JSON.stringify({
        treeData, filters: filterValues, searchQuery,
      }),
    })
  },
  getTrackedUrl: async (id) => safeFetchJSON(`${JIROY_API_URL}/v1/elasticsearch/trackedUrl/${id}`),
  getCategory: async (categoryKey) => {
    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v6/cache/getCategory/${categoryKey}`)
    return data
  },
  getPriceHistory: async (sku) => {
    if (!EXPERIMENTAL_PRICE_HISTORY) {
      return []
    }

    const { data: priceHistory } = await safeFetchJSON(`${JIROY_API_URL}/v6/elasticsearch/pricehistory/${sku}`)
    const result = getPriceHistoryWithFilledInGaps(priceHistory)
    return result
  },
}

export const Mail = {
  v1: {
    sendAnalyticsInterest: async (model) => {
      const { name, email } = model
      return safeFetchJSON(`${JIROY_API_URL}/v2/interest/subscribe/analytics`, {
        method: 'POST',
        body: JSON.stringify({
          name: name.value,
          email: email.value,
        }),
      })
    },
    contactUs: async (form) => {
      await safeFetchJSON(`${JIROY_API_URL}/v1/mail/contact`, {
        method: 'POST',
        body: JSON.stringify(form),
      })
    },
  },
  v2: {
    sendSubscription: async (model) => {
      const { email } = model
      return safeFetchJSON(`${JIROY_API_URL}/v2/interest/subscribe/newsletter`, {
        method: 'POST',
        body: JSON.stringify({ email }),
      })
    },
    sendOrdinaryPricesInterest: async (model) => {
      const { email } = model
      return safeFetchJSON(`${JIROY_API_URL}/v2/interest/subscribe/ordinary-prices`, {
        method: 'POST',
        body: JSON.stringify({ email: email.value }),
      })
    },
    sendMonitorSubscription: async (model) => {
      const { sku, email } = model
      return safeFetchJSON(`${JIROY_API_URL}/v2/interest/subscribe/expired-product`, {
        method: 'POST',
        body: JSON.stringify({ sku, email }),
      })
    },
  },
}

const getSitemapCategoryLinks = async () => {
  const { data } = await safeFetchJSON(`${JIROY_API_URL}/v1/sitemap/categories/get`, { method: 'GET' })
  return data
}

const getSitemapProductLinks = async () => {
  const { data } = await safeFetchJSON(`${JIROY_API_URL}/v1/sitemap/products/get`, { method: 'GET' })
  return data
}

const getSitemapDedicatedPages = async () => {
  const { data } = await safeFetchJSON(`${JIROY_API_URL}/v1/sitemap/dedicated-pages/get`, { method: 'GET' })
  return data
}

const getSitemapSalesPeriodLinks = async () => {
  const { data } = await safeFetchJSON(`${JIROY_API_URL}/v1/sitemap/sales-periods/get`, { method: 'GET' })
  return data
}

const getSitemapArticlesLinks = async () => {
  const { data } = await safeFetchJSON(`${JIROY_API_URL}/v1/sitemap/articles/get`, { method: 'GET' })
  return data
}

export const Sitemap = {
  getSitemapLinksFor: async (key) => {
    switch (key) {
    case sitemaps.CATEGORIES:
      return getSitemapCategoryLinks()
    case sitemaps.DEDICATED_PAGES:
      return getSitemapDedicatedPages()
    case sitemaps.PRODUCTS:
      return getSitemapProductLinks()
    case sitemaps.SALES_PERIODS:
      return getSitemapSalesPeriodLinks()
    case sitemaps.ARTICLES:
      return getSitemapArticlesLinks()

    default:
      return []
    }
  },
}

export const Cms = {
  getArticles: async () => fetch(`${JIROY_API_URL}/v2/cms/articles/getAll`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then(({ data }) => {
      if (!data) {
        return []
      }
      return data.filter((x) => x.slug && x.status === 'publish')
    })
    .catch((err) => {
      console.log(err)
      return []
    }),
  getArticleCategories: async () => {
    if (!EXPERIMENTAL_ARTICLE_CATEGORIES) {
      return []
    }

    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v2/cms/articles/getAllCategories`, {
      method: 'GET',
    })

    return data || []
  },
  getArticlesInCategory: async (array) => {
    const categoriesString = array.join()
    if (!EXPERIMENTAL_ARTICLE_CATEGORIES) {
      return []
    }

    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v3/cms/articles/getAllInCategory/${categoriesString}`, {
      method: 'GET',
    })

    return data || []
  },
  getArticle: async (slug) => {
    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v2/cms/articles/get/${slug}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return data
  },
  getSalesPeriods: async () => {
    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v2/cms/sales-periods/getAll/${50}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    if (!data || !data.posts) {
      return []
    }
    const current = dayjs()
    if (!data || !data.posts || !data.posts.nodes) {
      return []
    }
    return data.posts.nodes.filter((x) => dayjs(x.salesPeriodParameters.enddate, 'YYYY-MM-DD HH:mm:ss').diff(current, 'days') >= 1).sort(
      (a, b) => new Date(a.salesPeriodParameters.startdate) - new Date(b.salesPeriodParameters.startdate),
    )
  },
  getSalesPeriod: async (id) => {
    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v2/cms/sales-periods/get/${id}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    return data
  },
  getCopyData: async (uri) => {
    const { data } = await safeFetchJSON(`${JIROY_API_URL}/v4/cms/copy/get/${uri}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })

    const metaData = getMetaDataFromResult(data)
    return metaData
  },
}

export const saveToLocalStorage = (productToStore) => {
  if (productToStore) {
    const product = { ...productToStore, sku: productToStore._id }
    delete product._id
    let seenProducts
    try {
      // seenProducts = JSON.parse(localStorage.getItem(SEEN_PRODUCTS)) || []
    } catch (error) {
      seenProducts = []
    }
    if (seenProducts.map((x) => x.sku).indexOf(product.sku) === -1) {
      // trim
      seenProducts.unshift({ ...product, TTL: dayjs().add(30, 'day') })
    }
    try {
      // localStorage.setItem(SEEN_PRODUCTS, JSON.stringify(seenProducts))
    } catch (error) {
      // eslint-disable-next-line no-console
    }
  }
}
