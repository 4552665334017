export const getUpdatedQueryParams = (oldQueryParams, updatedKey, newValue) => {
  const updatedQueryParams = { ...oldQueryParams }
  updatedQueryParams[updatedKey] = newValue

  if (typeof newValue === 'undefined' || newValue === undefined || newValue === null || newValue === '') {
    delete updatedQueryParams[updatedKey]
  }
  return updatedQueryParams
}

export const getQueryStringFromQueryParams = (queryParams = {}) => {
  const queryParamArray = Object.entries(queryParams)
  const mappedValues = queryParamArray.map(([key, value]) => `${key}=${value}`)
  if (mappedValues.length === 0) {
    return ''
  }
  return `?${mappedValues.join('&')}`
}

export const getUrl = ({ path, queryParams }) => `${path}${getQueryStringFromQueryParams(queryParams)}`
