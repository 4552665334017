import { getMinMax, roundToClosestNth } from 'utils/math'
import { getQueryStringFromQueryParams, getUpdatedQueryParams } from 'utils/routing'
import { COOL_BRANDS_MAP, DEFAULT_PRICE_FILTER_OPTIONS } from './constants'

const APLHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

export const FILTER_TYPE_BRAND = 'brand'
export const FILTER_TYPE_COLOR = 'color'
export const FILTER_TYPE_GENDER = 'gender'
export const FILTER_TYPE_PRICE = 'price'
export const FILTER_TYPE_SALE = 'sale'
export const FILTER_TYPE_SIZE = 'size'
export const ALL_FILTER_TYPES = [FILTER_TYPE_SALE,
  FILTER_TYPE_BRAND, FILTER_TYPE_COLOR, FILTER_TYPE_GENDER, FILTER_TYPE_PRICE, FILTER_TYPE_SIZE]

export const ALLOWED_GENDER_FILTERS = ['Dam', 'Herr', 'Unisex', 'Barn', 'Baby']

const COLORS = {
  Vit: 'white',
  Svart: 'black',
  Grå: 'gray',
  Lila: 'purple',
  Blå: 'blue',
  Gul: 'yellow',
  Grön: 'green',
  Beige: 'beige',
  Brun: 'brown',
  Orange: 'orange',
  Rosa: 'pink',
  Röd: 'red',
}

const optionCategoriesMap = {
  letterSizes: ['clothing'],
  cupSizes: ['bh'],
  jeansSizes: ['jeans'],
  digitSizes: ['clothing'],
  childrenSizes: ['clothing'],
  shoeSizes: ['shoes'],
  oneSizeSizes: ['clothing'],
}

const mapToOptionCategories = (categories) => {
  const optionCategories = []
  categories.forEach((cat) => {
    const optionCats = optionCategoriesMap[cat]

    optionCats.forEach((c) => {
      if (!optionCategories.includes(c)) {
        optionCategories.push(c)
      }
    })
  })

  return optionCategories
}

const getCategoriesForSize = (size, categorizedSizes) => {
  const newMapping = categorizedSizes.reduce((acc, mapping) => {
    const [category, sizesForCategory] = Object.entries(mapping)?.[0] || []

    if (sizesForCategory && sizesForCategory) {
      acc.push({ category, sizesForCategory })
    }

    return acc
  }, [])

  return mapToOptionCategories(newMapping.filter(({ sizesForCategory }) => sizesForCategory.includes(size)).map(({ category }) => category))
}

export const restorePageQueryParam = (queryParams) => Object.keys(queryParams).reduce((acc, cur) => {
  if (cur === 'page') {
    return acc
  }
  return { ...acc, [cur]: queryParams[cur] }
}, {})

export const getQueryStringForFilter = (queryParams, filter, updatedKey, toggledId, selectOne) => {
  const prevSelectedIds = filter.state.selectedIds
  let urlValue

  if (selectOne) {
    urlValue = prevSelectedIds?.[0] === toggledId ? undefined : toggledId
  } else if (prevSelectedIds?.[0] === undefined) {
    urlValue = toggledId
  } else {
    let selectedIds = [...prevSelectedIds, toggledId]

    if (prevSelectedIds.includes(toggledId)) {
      selectedIds = prevSelectedIds.filter((id) => id !== toggledId)
    }

    urlValue = selectedIds.join('_')
  }

  const updatedQueryParams = restorePageQueryParam(getUpdatedQueryParams(queryParams, updatedKey, urlValue))
  return getQueryStringFromQueryParams(updatedQueryParams)
}

export const getQueryStringRange = (queryParams, paramKey, gte, lte) => {
  const urlValue = `${gte}-${lte}`
  const updatedQueryParams = restorePageQueryParam(getUpdatedQueryParams(queryParams, paramKey, urlValue))
  return getQueryStringFromQueryParams(updatedQueryParams)
}

export const getQueryStringSaleRange = (queryParams, paramKey, gte, lte) => {
  const urlValue = `${gte}-${lte}`
  const updatedQueryParams = restorePageQueryParam(getUpdatedQueryParams(queryParams, paramKey, urlValue))
  return getQueryStringFromQueryParams(updatedQueryParams)
}

export const getFilterStateFromQueryParams = (queryParams, paramKey, hasCustomValue) => {
  const urlValue = queryParams?.[paramKey]
  let state
  if (hasCustomValue && urlValue && urlValue.includes('-')) {
    if (paramKey === 'price' || paramKey === 'sale') {
      const gte = Number(urlValue.split('-')[0])
      const lte = Number(urlValue.split('-')[1])

      if ((gte && lte) || gte === 0 || lte === 0) {
        state = { gte, lte, isCustomValue: true }
      }
    }
  } else if (urlValue !== undefined) {
    if (urlValue.includes('_')) {
      const selectedIds = urlValue.split('_').map((id) => id)
      state = { selectedIds, isCustomValue: false }
    } else {
      state = { selectedIds: [urlValue], isCustomValue: false }
    }
  }

  return state
}

export const getSelectedIdsFromFilterState = (filterState) => filterState?.selectedIds || null

export const getRangeFromFilterState = (filterState, filterOptions = null) => {
  if (
    filterState
    && filterState.isCustomValue
    && (filterState.lte || filterState.lte === 0)
    && (filterState.gte || filterState.gte === 0)) {
    return { gte: filterState.gte, lte: filterState.lte }
  }

  if (filterOptions && filterState?.selectedIds) {
    const selectedOptions = filterOptions.filter((option) => filterState.selectedIds.includes(option.id))
    const minMax = getMinMax(selectedOptions, 'gte', 'lte')
    return { gte: minMax.min, lte: minMax.max }
  }

  return null
}

export const getFilterLabel = (filter, filterType) => {
  switch (filterType) {
  case FILTER_TYPE_PRICE: {
    const {
      selectedIds, isCustomValue, gte, lte,
    } = filter.state

    if (selectedIds && selectedIds.length > 0) {
      const selectedOptions = filter.options.filter((option) => selectedIds.includes(option.id))
      const minMax = getMinMax(selectedOptions, 'gte', 'lte')
      const { min, max } = minMax || {}

      if (min && max) {
        return `${min}-${max} kr`
      }
    } else if (isCustomValue && (gte || gte === 0) && (lte || lte === 0)) {
      return `${gte}-${lte} kr`
    }

    return 'Pris'
  }
  case FILTER_TYPE_SALE: {
    const {
      selectedIds, isCustomValue, gte, lte,
    } = filter.state

    if (selectedIds && selectedIds.length > 0) {
      const selectedOptions = filter.options.filter((option) => selectedIds.includes(option.id))
      const minMax = getMinMax(selectedOptions, 'gte', 'lte')
      const { min, max } = minMax || {}

      if (min && max) {
        return `${min}-${max} %`
      }
    } else if (isCustomValue && (gte || gte === 0) && (lte || lte === 0)) {
      return `${gte}-${lte} %`
    }

    return 'Rea'
  }
  case FILTER_TYPE_SIZE: {
    const { selectedIds } = filter.state

    if (selectedIds && selectedIds.length > 0) {
      const selectedOptions = filter.options.filter((option) => selectedIds.includes(option.id))
      const minMax = getMinMax(selectedOptions, 'gte', 'lte')
      const { min, max } = minMax || {}

      if (min && max) {
        if (min === max) {
          return min
        }

        return `${min}-${max}`
      }
    }

    return 'Storlek'
  }
  case FILTER_TYPE_COLOR: {
    const { selectedIds } = filter.state

    if (selectedIds && selectedIds.length >= 0) {
      const selectedOptions = filter.options.filter((option) => selectedIds.includes(option.id))

      if (selectedOptions?.length === 1 && selectedOptions[0].label) {
        return selectedOptions[0]?.label
      }

      if (selectedOptions?.length > 1) {
        return `${selectedOptions.length} färger`
      }
    }

    return 'Färg'
  }
  case FILTER_TYPE_BRAND: {
    const { selectedIds } = filter.state

    if (selectedIds && selectedIds.length >= 0) {
      const selectedOptions = filter.options.filter((option) => selectedIds.includes(option.id))

      if (selectedOptions?.length === 1 && selectedOptions[0].label) {
        return selectedOptions[0]?.label
      }

      if (selectedOptions?.length > 1) {
        return `${selectedOptions.length} märken`
      }
    }

    return 'Märken'
  }
  case FILTER_TYPE_GENDER: {
    const { selectedIds } = filter.state

    if (selectedIds && selectedIds.length > 0) {
      const selectedOptions = filter.options.filter((option) => selectedIds.includes(option.id))
      const label = selectedOptions?.[0]?.label

      if (label) {
        return label
      }
    }

    return 'Kön'
  }
  default:
    return 'Filter'
  }
}

const removePercentilesThatRoundsToSame = (percentilesArray) => {
  const usedRoundings = []
  return percentilesArray.reduce((acc, [percKey, percValue]) => {
    const roundedValue = roundToClosestNth(percValue, 100)

    if (usedRoundings.includes(roundedValue)) {
      return acc
    }

    usedRoundings.push(roundedValue)
    acc.push([percKey, percValue])
    return acc
  }, [])
}

export const getPriceFilterOptions = (dataFromES) => {
  const percentilesMap = dataFromES?.aggregations?.price_percentiles?.values
  let priceFilterOptions

  if (percentilesMap) {
    const percentilesArray = Object.entries(percentilesMap || {})
    const percentilesWithoutDuplicates = removePercentilesThatRoundsToSame(percentilesArray)
    priceFilterOptions = percentilesWithoutDuplicates.reduce((acc, [, value], index) => {
      if (index === 0) {
        return acc
      }

      const lastIndex = index - 1
      const gte = roundToClosestNth(percentilesWithoutDuplicates[lastIndex]?.[1] || 0, 100)
      const lte = roundToClosestNth(value, 100)

      if ((gte || gte === 0) && lte) {
        acc.push({
          id: APLHABET.charAt(lastIndex), gte, lte, label: `${gte.toLocaleString('sv-SE')} kr - ${lte.toLocaleString('sv-SE')} kr`,
        })
      }

      return acc
    }, [])
  }

  if (priceFilterOptions) {
    return priceFilterOptions
  }

  return DEFAULT_PRICE_FILTER_OPTIONS
}

export const isFiltersSelected = (queryParams) => Object.keys(queryParams).some((queryKey) => ALL_FILTER_TYPES.includes(queryKey))

export const getBrandFilterOptions = (dataFromES) => {
  const brandsArray = dataFromES?.aggregations?.brandsArray

  if (!brandsArray) {
    return null
  }

  const brandOptions = brandsArray.map((brand) => ({ id: brand, label: brand, show: !!COOL_BRANDS_MAP[brand] }))
  const optionsExists = brandOptions.some((option) => option.show)

  if (!optionsExists) {
    return null
  }

  return brandOptions
}

export const getSizeFilterOptions = (filterDataFromES, productDataFromES) => {
  const sortedSizes = filterDataFromES?.aggregations?.sortedSizes
  const categorizedSizes = filterDataFromES?.aggregations?.categorizedSizes
  const sizeCounts = productDataFromES?.custom?.sizeCounts || {}

  if (!sortedSizes) {
    return null
  }

  const sizeOptions = sortedSizes.map((size) => ({
    id: size ? `${size}` : null,
    label: `${size}`,
    categories: getCategoriesForSize(size, categorizedSizes),
    count: sizeCounts[size] || 0,
  }))
  const validSizeOptions = sizeOptions.filter((option) => option.id)

  if (!validSizeOptions.length > 0) {
    return null
  }

  return validSizeOptions
}

export const getColorFilterOptions = (filterDataFromES, productDataFromES) => {
  const colorsArray = filterDataFromES?.aggregations?.colorsArray
  const colorCounts = productDataFromES?.custom?.colorCounts || {}

  if (!colorsArray) {
    return null
  }

  const colorOptions = colorsArray.map((color) => ({
    id: color, color: COLORS[color], label: color, count: colorCounts[color] || 0,
  })).filter((cMap) => !!cMap.color)
  const validColorOptions = colorOptions.filter((option) => option.id && option.label && option.color)

  if (!validColorOptions.length > 0) {
    return null
  }

  return validColorOptions
}

export const getGenderFilterOptions = (filterDataFromES, productDataFromES) => {
  const gendersArray = filterDataFromES?.aggregations?.gendersArray
  const genderCounts = productDataFromES?.custom?.genderCounts || {}

  if (!gendersArray) {
    return null
  }

  const genderOptions = gendersArray.map((gender) => ({ id: gender, label: gender, count: genderCounts[gender] || 0 }))
  const validGenderOptions = genderOptions.filter((option) => option.id && option.label && ALLOWED_GENDER_FILTERS.includes(option.label))

  if (!validGenderOptions.length > 0) {
    return null
  }

  return validGenderOptions
}
