import { useCallback, useEffect, useRef } from 'react'
import Router from 'next/router'

const getSessionKey = (url) => `scroll-${url}`

const saveScrollPos = (url) => {
  const scrollPos = { x: window.scrollX, y: window.scrollY }
  sessionStorage.setItem(getSessionKey(url), JSON.stringify(scrollPos))
}

export default function useSaveScrollPosition(router) {
  const urlToRestore = useRef(null)

  const restorePositionForUrl = useCallback((url) => {
    const scrollPos = JSON.parse(sessionStorage.getItem(getSessionKey(url)))

    if (scrollPos) {
      window.scrollTo(scrollPos.x, scrollPos.y)
    }
  }, [])

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual'
    }

    const onBeforeUnload = (event) => {
      saveScrollPos(router.asPath)
      // eslint-disable-next-line no-param-reassign
      delete event.returnValue
    }

    const onRouteChangeStart = () => {
      saveScrollPos(router.asPath)
    }

    const onRouteChangeComplete = (url) => {
      if (urlToRestore.current === url) {
        urlToRestore.current = null
        restorePositionForUrl(url)
      }
    }

    window.addEventListener('beforeunload', onBeforeUnload)
    Router.events.on('routeChangeStart', onRouteChangeStart)
    Router.events.on('routeChangeComplete', onRouteChangeComplete)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
      Router.events.off('routeChangeStart', onRouteChangeStart)
      Router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router, restorePositionForUrl])

  const scheduleScrollRestorationForUrl = useCallback((url) => {
    urlToRestore.current = url
  }, [])

  return { scheduleScrollRestorationForUrl }
}
