import { EXPERIMENTAL_BRAND_FILTERING } from 'configs/experimental'

export const COOL_BRANDS = EXPERIMENTAL_BRAND_FILTERING ? ['Husqvarna', 'Jotex SE', 'Mustang', 'Diesel', 'Björn Borg'] : []

export const COOL_BRANDS_MAP = COOL_BRANDS.reduce((acc, brand) => {
  acc[brand] = true
  return acc
}, {})

// Filters will always show if default options exist.
export const DEFAULT_PRICE_FILTER_OPTIONS = [{
  gte: 0,
  lte: 300,
  label: '0 kr - 300 kr',
}, {
  gte: 300,
  lte: 700,
  label: '300 kr - 700 kr',
}, {
  gte: 700,
  lte: 3000,
  label: '700 kr - 3 000 kr',
}, {
  gte: 3000,
  lte: 10000,
  label: '3000 kr - 10 000 kr',
}, {
  gte: 10000,
  lte: 9999999,
  label: '> 10 000 kr',
}]

// Filters will always show if default options exist.
export const DEFAULT_SALE_FILTER_OPTIONS = [{
  gte: 10,
  lte: 100,
  label: '>= 10 %',
}, {
  gte: 20,
  lte: 100,
  label: '>= 20 %',
}, {
  gte: 30,
  lte: 100,
  label: '>= 30 %',
}, {
  gte: 40,
  lte: 100,
  label: '>= 40 %',
}, {
  gte: 50,
  lte: 100,
  label: '>= 50 %',
}]
