import { useEffect } from 'react'
import { keepStateOnNavigate } from 'store/actions/pageReducer'
import {
  isCategoryPage, isDedicatedPage, isProductPage, isSearchPage,
} from 'utils/app'
import store from 'store/store'
import useSaveScrollPosition from './useSaveScrollPosition'

const useHistoryButtons = ({ router }) => {
  const { scheduleScrollRestorationForUrl } = useSaveScrollPosition(router)

  useEffect(() => {
    router.beforePopState((event) => {
      const { as } = event
      const fromPath = router.asPath
      const toPath = as

      if (toPath !== fromPath) {
        const navigatedProdToCat = isProductPage(fromPath) && isCategoryPage(toPath)
        const navigatedCatToProd = isProductPage(toPath) && isCategoryPage(fromPath)
        const navigatedProdToProd = isProductPage(toPath) && isProductPage(fromPath)
        const navigatedProdToSearch = isProductPage(fromPath) && isSearchPage(toPath)
        const navigatedSearchToProd = isSearchPage(toPath) && isProductPage(fromPath)
        const navigatedProdToDedicated = isProductPage(fromPath) && isDedicatedPage(toPath)
        const nativatedDedicatedToProd = isDedicatedPage(toPath) && isProductPage(fromPath)

        // Keep category page state when we navigate from category to product page using browser back or forward button.
        if (navigatedCatToProd) {
          keepStateOnNavigate('category')()
        }

        // Keep category page state when we navigate from product to category page using browser back or forward button.
        // Keep state in scenario category => product (back)=> category (forward)=>product => (back)=> category.
        // Don't keep state in scenario category => product => view related or breadcrumb (back)=>product => (back) => category.
        if (navigatedProdToCat) {
          const { lastCategoryUrl } = store.getState().pageReducer.category

          if (lastCategoryUrl === toPath) {
            keepStateOnNavigate('category')()
            scheduleScrollRestorationForUrl(toPath)
          }
        }

        // Keep category page state when we navigate between search and product page using browser back or forward button.
        if (navigatedProdToSearch || navigatedSearchToProd || navigatedProdToProd) {
          keepStateOnNavigate('search')()
        }

        // Keep category page state when we navigate between dedicated and product page using browser back or forward button.
        if (navigatedProdToDedicated || nativatedDedicatedToProd || navigatedProdToProd) {
          keepStateOnNavigate('butik')()
        }

        // Restore scroll position when navigating back to category/search/dedicated from product page using browser back or forward button.
        if (navigatedProdToSearch || navigatedProdToDedicated) {
          scheduleScrollRestorationForUrl(toPath)
        }
      }

      return true
    })

    return () => {
      router.beforePopState(() => true)
    }
  }, [router, scheduleScrollRestorationForUrl])

  return null
}

export default useHistoryButtons
