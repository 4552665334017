export const getMinMax = (objList, minAttr, maxAttr) => {
  const minMax = objList.reduce((acc, obj) => {
    if (obj[minAttr] < acc.min) {
      acc.min = obj[minAttr]
    }

    if (obj[maxAttr] > acc.max) {
      acc.max = obj[maxAttr]
    }

    return acc
  }, { min: Infinity, max: -1 })

  if (minMax.min === Infinity || minMax.max === -1) {
    return null
  }

  return minMax
}

export const roundToClosestNth = (num, n) => (num && n ? Math.round(num / n) * n : 0)

export const isRangeWithinRange = (containingMinRangeA, containingMaxRangeA, minRange, maxRange) => {
  if (minRange >= containingMinRangeA && maxRange <= containingMaxRangeA) {
    return true
  }
  return false
}

export const readingTime = (text = '') => {
  const wpm = 200
  if (!text) {
    return { minutes: 0 }
  }
  const words = text.trim().split(/\s+/).length
  return { minutes: Math.ceil(words / wpm) }
}

export const getReadMinutes = (content) => {
  const minutes = Math.ceil(readingTime(content).minutes)
  return minutes > 1 ? `${minutes} minuter` : `${minutes} minut`
}
