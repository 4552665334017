import { useEffect } from 'react'
import { pageNavigation } from 'store/actions/pageReducer'
import { isCategoryPage, isProductPage } from 'utils/app'
import store from 'store/store'

let firstVisit = true

const usePageListenerForRedux = ({ router }) => {
  if (firstVisit) {
    firstVisit = false
    pageNavigation({ url: router.asPath, shallow: false, preventKeepState: false })
  }
  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      let preventKeepState = false
      const fromUrl = router.asPath
      const toUrl = url
      const navigatedProdToCat = isProductPage(fromUrl) && isCategoryPage(toUrl)

      if (navigatedProdToCat) {
        const { lastCategoryUrl } = store.getState().pageReducer.category

        if (lastCategoryUrl !== toUrl) {
          preventKeepState = true
        }
      }

      pageNavigation({ url, shallow, preventKeepState })
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  return null
}

export default usePageListenerForRedux
