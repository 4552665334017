/* eslint-disable import/prefer-default-export */

const EXPERIMENTAL_PRICE_FILTERING = true
const EXPERIMENTAL_BRAND_FILTERING = false
const EXPERIMENTAL_SIZE_FILTERING = true
const EXPERIMENTAL_COLOR_FILTERING = true
const EXPERIMENTAL_GENDER_FILTERING_CATEGORY = false
const EXPERIMENTAL_GENDER_FILTERING_SEARCH = false
const EXPERIMENTAL_SALE_FILTERING = false
const EXPERIMENTAL_SUB_CATEGORIES = true
const EXPERIMENTAL_PRICE_HISTORY = true
const EXPERIMENTAL_ARTICLE_CATEGORIES = true
const EXPERIMENTAL_ABOUT_PROGRAM = true
const EXPERIMENTAL_GEO_INFO = true
const EXPERIMENTAL_ENVIRONMENTAL_INFO = true

const SOME_EXPERIMENTAL_FILTERING = EXPERIMENTAL_PRICE_FILTERING
  || EXPERIMENTAL_BRAND_FILTERING
  || EXPERIMENTAL_SIZE_FILTERING
  || EXPERIMENTAL_COLOR_FILTERING
  || EXPERIMENTAL_GENDER_FILTERING_CATEGORY
  || EXPERIMENTAL_GENDER_FILTERING_SEARCH
  || EXPERIMENTAL_SALE_FILTERING

const EXPERIMENTALS_TO_BE_DISABLED = [
  // EXPERIMENTAL_SIZE_FILTERING,
  EXPERIMENTAL_GENDER_FILTERING_SEARCH,
  // EXPERIMENTAL_COLOR_FILTERING,
  EXPERIMENTAL_BRAND_FILTERING,
  EXPERIMENTAL_GENDER_FILTERING_CATEGORY,
  EXPERIMENTAL_SALE_FILTERING,
  // EXPERIMENTAL_PRICE_HISTORY,
  // EXPERIMENTAL_ARTICLE_CATEGORIES,
  // EXPERIMENTAL_ABOUT_PROGRAM,
  // EXPERIMENTAL_GEO_INFO,
  // EXPERIMENTAL_ENVIRONMENTAL_INFO,
]

module.exports = {
  EXPERIMENTALS_TO_BE_DISABLED,
  EXPERIMENTAL_PRICE_FILTERING,
  EXPERIMENTAL_BRAND_FILTERING,
  EXPERIMENTAL_SIZE_FILTERING,
  EXPERIMENTAL_COLOR_FILTERING,
  EXPERIMENTAL_GENDER_FILTERING_CATEGORY,
  EXPERIMENTAL_GENDER_FILTERING_SEARCH,
  EXPERIMENTAL_SALE_FILTERING,
  EXPERIMENTAL_SUB_CATEGORIES,
  SOME_EXPERIMENTAL_FILTERING,
  EXPERIMENTAL_PRICE_HISTORY,
  EXPERIMENTAL_ARTICLE_CATEGORIES,
  EXPERIMENTAL_ABOUT_PROGRAM,
  EXPERIMENTAL_GEO_INFO,
  EXPERIMENTAL_ENVIRONMENTAL_INFO,
}
