/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { createWrapper } from 'next-redux-wrapper'
import { Provider, useDispatch } from 'react-redux'
import usePageListenerForRedux from 'Hooks/usePageListenerForRedux'
import useHistoryButtons from 'Hooks/useHistoryButtons'
// import Script from 'next/script'
import { setSwitchKeyAction } from 'store/actions/main'
import store from '../store/store'
import {
  consoleInitialMessage, switchKeys, removeOldSeenProducts, removeOldFavoritedProducts,
} from '../helper'
/**
 * Third parties
 */
/**
 * Swiper.JS <https://swiperjs.com/>
 */
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import '../styles/_index.scss'

let originalLocationInjected = false
const MyApp = ({ Component, pageProps }) => {
  const router = useRouter()
  const dispatch = useDispatch()

  useHistoryButtons({ router })
  usePageListenerForRedux({ router })

  useEffect(() => {
    dispatch(setSwitchKeyAction(switchKeys.SALES.key, router.query.sales === 'true'))
    dispatch(setSwitchKeyAction(switchKeys.IN_STOCK.key, router.query.instock === 'true'))
  }, [router.query.sales, router.query.instock, dispatch])

  useEffect(() => {
    removeOldSeenProducts()
    removeOldFavoritedProducts()
    consoleInitialMessage()
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  }, [])

  /**
   * DataLayer Implementation Guide
   * @description 2.2.1 original_location
  */
  const injectOriginalLocation = () => {
    if (originalLocationInjected) {
      return undefined
    }
    originalLocationInjected = true
    return (
      <script
        defer
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "original_location",
              original_location: "https://jiroy.com${router.asPath}",
            });
          `,
        }}
      />
    )
  }
  return (
    <>
      <Head>
        {injectOriginalLocation()}
        <meta name="msapplication-TileColor" content="#8d0000" />
        <meta name="theme-color" content="#8d0000" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
        <meta name="google" content="notranslate" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        {/* <!-- Start Google Tag Manager --> */}
        <script
          defer
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PG9P5D5');`,
          }}
        />
        {/* <!-- End Google Tag Manager --> */}
        {/* <!-- Brain Tag for jiroy.com v2.0 --> */}
        <script
          defer
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i,f,j){f=d.getElementsByTagName(s)[0];
              j=d.createElement(s);w['DEPObject']=l;w[l+'Data']=w[l+'Data']||[];
              w[l]=w[l]||function(){w[l].q.push(arguments)};w[l].q=w[l+'Data'];j.async=1;
              j.src='//cdn.dep-x.com/t.js?id='+i; f.parentNode.insertBefore(j,f);
            })(window,document,'script','brain','DEP-KDJMEDEHWRBM');`,
          }}
        />
        {/* <!-- End Brain Tag --> */}

        {/* Hotjar Tracking Code for jiroy.com */}
        {/*
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:2574613,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          }}
        />
        */}

        {/* Mouseflow  */}
        <script
          dangerouslySetInnerHTML={{
            __html: `window._mfq = window._mfq || [];
(function() {
  var mf = document.createElement("script");
  mf.type = "text/javascript"; mf.defer = true;
  mf.src = "//cdn.mouseflow.com/projects/9ffbd36e-232b-4c9a-85b9-2ff3de9894da.js";
  document.getElementsByTagName("head")[0].appendChild(mf);
})();`,
          }}
        />

      </Head>
      <Provider store={store}>
        <Component {...pageProps} />
        {/* <Snackbar /> */}
        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
          <iframe
            title="dtm-title"
            src="https://www.googletagmanager.com/ns.html?id=GTM-PG9P5D5"
            height={0}
            width={0}
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}

        {/* <Script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="754d22ca-d4bd-44e8-94c1-2b86b72d718e"
          type="text/javascript"
        /> */}
      </Provider>
    </>
  )
}
const makeStore = () => store
const wrapper = createWrapper(makeStore)
export default wrapper.withRedux(MyApp)
