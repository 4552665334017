import {
  SET_SEARCH_RESULT_DATA,
  SET_SEARCH_RESULT_PAGE_COUNTER,
  SET_INNER_WIDTH,
  SET_SEARCH_PAGE_SORT_ORDER,
  ON_FAVORITE_BUTTON_CLICK,
  HYDRATE_PRODUCTS,
  HYDRATE_AMOUNT_INDICATOR,
  TRIGGER_MODAL_IS_OPEN,
  SET_SEARCH_MODAL_DATA,
  SET_SEARCH_MODAL_INPUT_VALUE,
  TRIGGER_RIGHT_DRAWER_ITEM_PRESSED,
  SET_BACKGROUND_SHADOW,
  SET_SEARCH_MODAL_NO_HITS,
  REVERSE_ON_SALE,
  REVERSE_SWITCH_KEY,
} from '../types'

export const setSearchResultDataAction = (data) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_RESULT_DATA,
    payload: data,
  })
}

export const setSearchResultPageCounterAction = (page) => async (dispatch) => {
  dispatch({
    type: SET_SEARCH_RESULT_PAGE_COUNTER,
    payload: page,
  })
}

export const hydrateProductsAction = () => async (dispatch, getState) => {
  const { productsHydrate } = getState().main
  dispatch({
    type: HYDRATE_PRODUCTS,
    payload: !productsHydrate,
  })
}

export const triggerRightDrawerItemPressedAction = () => async (dispatch, getState) => {
  const { rightDrawerItem } = getState().main
  dispatch({
    type: TRIGGER_RIGHT_DRAWER_ITEM_PRESSED,
    payload: !rightDrawerItem,
  })
}

export const onFavoriteButtonClickAction = (favorited) => async (dispatch, getState) => {
  const { amountIndicateHydrate } = getState().main
  dispatch({
    type: HYDRATE_AMOUNT_INDICATOR,
    payload: !amountIndicateHydrate,
  })

  if (!favorited) {
    return
  }
  dispatch({
    type: ON_FAVORITE_BUTTON_CLICK,
    payload: true,
  })

  setTimeout(() => {
    dispatch({
      type: ON_FAVORITE_BUTTON_CLICK,
      payload: false,
    })
  }, 1500)
}

export const setSearchPageSortOrder = (sortOrder) => async (dispatch) => {
  dispatch({
    type: SET_SEARCH_PAGE_SORT_ORDER,
    payload: sortOrder,
  })
}

export const setInnerWidth = (width) => async (dispatch) => {
  dispatch({
    type: SET_INNER_WIDTH,
    payload: width,
  })
}

export const triggerSearchModalAction = () => async (dispatch, getState) => {
  const { searchModalIsOpen } = getState().main
  const payload = !searchModalIsOpen

  dispatch({
    type: TRIGGER_MODAL_IS_OPEN,
    payload,
  })
}

export const setSearchModalIsOpenAction = (open) => async (dispatch) => {
  dispatch({
    type: TRIGGER_MODAL_IS_OPEN,
    payload: open,
  })
}

export const setSearchModalDataAction = (hits) => async (dispatch, getState) => {
  const { searchModalData } = getState().main
  dispatch({
    type: SET_SEARCH_MODAL_DATA,
    payload: hits,
  })
  dispatch({
    type: SET_SEARCH_MODAL_NO_HITS,
    payload: searchModalData.hits ? hits.hits.length === 0 : false,
  })
}

export const setSearchModalInputValueAction = (inputValue) => async (dispatch) => {
  dispatch({
    type: SET_SEARCH_MODAL_INPUT_VALUE,
    payload: inputValue,
  })
}
export const setBackgroundShadowAction = (active) => async (dispatch) => {
  dispatch({
    type: SET_BACKGROUND_SHADOW,
    payload: active,
  })
}

export const setSwitchKeyAction = (key, active) => async (dispatch) => {
  dispatch({
    type: REVERSE_SWITCH_KEY,
    payload: { key, active },
  })
}
